<template>
  <div class="container pd100 schedule-calendar">
      <vue-hash-calendar 
        ref="calendar"
        @click="handleClick"
        @change="handleChange"
        @slidechange="handleSlidechange"
        checkedDayClassName="schedule-calendar-color"
        format="YY-MM-DD"
        :visible="true" 
        :isShowArrow="false" 
        :isShowAction="false" 
        :isShowWeekView="isShowWeekView"
        :scrollChangeDate="true"
        :markDate="markDate">
        <!-- <div @click="isShowWeeks" slot="arrow">
          <img v-if="isShowWeekViews" src="@/assets/download.png">
          <img v-else src="@/assets/download-2.png">
        </div> -->
      </vue-hash-calendar>

      <div v-if="list.length > 0" class="schedule-list">
        <div @click="toDetail(course)" v-for="course in list" :key="course.book_id" class="schedule-list-item">
          <div class="schedule-list-item-hd">
            <div class="schedule-list-item-dot" />
            <div class="schedule-list-item-time">
              <div class="schedule-list-item-time-title">{{ course.class_start }}</div>
              <!-- <div v-if="course.book_status === 1" class="schedule-list-item-time-txt">待上课</div> -->
              <div v-if="course.book_status === 2" class="schedule-list-item-time-txt disabled">已上课</div>
              <div v-if="course.book_status === 0" class="schedule-list-item-time-txt orange">已请假</div>
            </div>
          </div>
          <div class="schedule-list-item-bd">
            <div class="schedule-list-item-title van-ellipsis">
              {{ course.course_title }}
            </div>

            <!-- <div v-if="course.course_teacher" class="schedule-list-item-teacher">              
              <span>老师：<van-image class="schedule-list-item-teacher-img" :src="course.course_teacher.teacher_avatar" />{{ course.course_teacher.teacher_name }}</span>
            </div> -->
            <div class="schedule-list-item-location">上课老师：{{ course.course_teacher && course.course_teacher.teacher_name }} </div>
            <div class="schedule-list-item-location">上课教室：{{ course.room_name }}</div>
            <div class="schedule-list-item-location">上课校区：{{ course.course_studio && course.course_studio.studio_name }} </div>
          </div>
        </div>
      </div>
      <div v-else class="no-data">
        <img :src="no_order" class="no-data-image">
        <div class="no-data-title">暂无排课</div>
      </div>
    <loadings :loading='searchLoading' />
  </div>
</template>

<script>
import { Tabbar, TabbarItem, Calendar } from 'vant'
import Schedule from '@/components/Schedule'
import Loadings from '@/components/loading'
import PageMixin from '@/mixins/page'
import dayjs from 'dayjs'
import no_order from '@/assets/no_order.png'

export default {
  name: 'Timetable',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Calendar.name]: Calendar,
    schedule: Schedule,
    loadings:Loadings
  },
  mixins: [PageMixin],
  data() {
    return {
      active: dayjs().format('YYYY-MM-DD'),
      weeks: [],
      no_order,

      searchLoading:false,
      isShowWeekView:false,
      isShowWeekViews:false,
      list: [],
      markDate:[],
      start_date:dayjs(dayjs().startOf('month')).add(-1, 'month').format('YYYY-MM-DD'),
      end_date:dayjs(dayjs().endOf('month')).add(1, 'month').format('YYYY-MM-DD'),
    }
  },
  created() {
    this.isShowWeekView = localStorage.getItem('isShowWeekViews')=='true'?true:false
    this.isShowWeekViews = localStorage.getItem('isShowWeekViews')=='true'?true:false
    this.getList()
  },
  methods: {
    getList(){
      this.searchLoading = true
      this.$api.schedule_index({ start_date:this.start_date, end_date:this.end_date}).then(res => {
        let markDate = []
        this.weeks = res.data.booking
        res.data.booking.forEach(item=>{
          markDate.push(dayjs(item.date).format('YYYY/MM/DD'))
          if(item.date == this.active){
            this.list = item.courses
          }
        })
        this.markDate = [{color: '#ecbe4b',date: markDate}]
        this.searchLoading = false
      })
    },
    handleClick(e){
      for(let i=0; i<this.weeks.length;i++){
        if(this.weeks[i].date == e){
          this.list = this.weeks[i].courses
          break
        }else{
          this.list = []
        }
      }
    },
    handleChange(e){
      this.active = e
      this.start_date = dayjs(dayjs(e).startOf('month')).add(-1, 'month').format('YYYY-MM-DD')
      this.end_date = dayjs(dayjs(e).endOf('month')).add(1, 'month').format('YYYY-MM-DD')
    },
    handleSlidechange(e){
      if (e=='up' || e=='down') return false
      let that = this
      setTimeout(()=>{
        that.getList()
      },0)
    },
    toDetail(item){
      this.$router.push({path:'/student/schedule/detail',query:{book_id:item.book_id}})
    },
    isShowWeeks(){
      this.isShowWeekViews = !this.isShowWeekViews
      localStorage.setItem('isShowWeekViews',this.isShowWeekViews)
    }
  }
}
</script>
<style lang="less" scoped>
  .schedule-list{
    min-height: 220px;
    padding: 10px 15px;
  }

  @height:80px;
  .schedule-list-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    background: #fff;
    padding-left: 10px;
    &-hd{
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-right: 20px;
      height: @height;
      padding-left: 20px;
      padding-top: 8px;
    }
    &-dot{
      position: absolute;
      top: 14px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      overflow: hidden;
      background: #00DE93;
      margin-right: 20px;
    }
    &-time{
      font-weight: bold;
      font-size: 16px;
      &-title{
        margin-bottom: 5px;
      }
      &-txt{
        font-size: 12px;
        font-weight: normal;
      }
    }
    &-title{
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
    }
    &-teacher{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 10px;
      color: #666;
      margin-bottom: 5px;
      &-img{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 5px;
      }
    }
    &-bd{
      box-sizing: border-box;
      // height: @height;
      padding: 10px 15px;
      // background: #f6f9fc;
      border-radius: 4px;
      flex: 1;
      font-size: 10px;
      color: #666;
      min-width: 0;
    }
    .gray{
      color: #b2b2b2;
    }
    .orange{
      color: #ff993e;
    }
  }

  .no-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-sizing: border-box;
    min-height: 220px;
    text-align: center;
    &-image{
      width: 96px;
      height: 96px;
      background: #fff;
      margin-bottom: 10px;
    }

  }
</style>
<style lang="less">
  .schedule-calendar{
    .calendar_dot{
      width: 4PX;
      height: 4PX;
    }
    .calendar_content{
      overflow: initial;
    }
    .calendar_day{
      font-size:3.33333333333334vw;
    }
    .schedule-calendar-color{
        background:#00cca2;
        color:#fff !important;
    }
    .calendar_first_today{
      color:#00cca2;
    }
  }
</style>

